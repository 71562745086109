@mixin summary {
  .summary {
    padding-top: 1rem;
    padding-bottom: .8rem;
    color: $global-font-color;
    border-bottom: 1px dashed $global-border-color;

    .dark-theme & {
      color: $global-font-color-dark;
      border-bottom: 1px dashed $global-border-color-dark;
    }

    .featured-image-preview {
      width: 100%;
      padding: 30% 0 0;
      position: relative;
      margin: 0.6rem auto;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .single-title {
      font-size: 1.4rem;
      line-height: 140%;
      margin: 0.4rem 0;
    }

    .content {
      display: -moz-box;
      display: -webkit-box;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-top: .3rem;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      color: $global-font-secondary-color;

      .dark-theme & {
        color: $global-font-secondary-color-dark;
      }

      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1rem;
        display: inline;

        &::after {
          content: "\A";
          white-space: pre;
        }
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 2;

        &::before {
          content: "|";
          margin-right: .3125rem;
          color: $global-link-color;

          .dark-theme & {
            color: $global-link-color-dark;
          }
        }
      }

      h2 {
        font-size: 1.2rem;

        &::before {
          content: "#";
        }
      }

      a {
        color: $global-link-color;

        .dark-theme & {
          color: $global-link-color-dark;
        }

        &:hover {
          color: $global-link-hover-color;

          .dark-theme & {
            color: $global-link-hover-color-dark;
          }
        }
      }

      b, strong {
        .dark-theme & {
          color: $global-font-secondary-color-dark;
        }
      }
    }

    .post-footer {
      margin-top: .4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .875rem;

      a {
        color: $single-link-color;

        .dark-theme & {
          color: $single-link-color-dark;
        }

        &:hover {
          color: $single-link-hover-color;

          .dark-theme & {
            color: $single-link-hover-color-dark;
          }
        }
      }

      .post-tags {
        padding: 0;

        a {
          color: $global-link-color;

          .dark-theme & {
            color: $global-link-color-dark;
          }

          &:hover {
            color: $global-link-hover-color;

            .dark-theme & {
              color: $global-link-hover-color-dark;
            }
          }
        }
      }
    }
  }
}
