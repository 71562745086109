.tag-cloud-tags {
  margin: 10px 0;

  a {
    display: inline-block;
    position: relative;
    margin: 5px 10px;
    overflow-wrap: break-word;
    -webkit-transition: all ease-out .3s;
    -moz-transition: all ease-out .3s;
    -o-transition: all ease-out .3s;
    transition: all ease-out .3s;

    &:active,
    &:focus,
    &:hover {
      color: $global-link-hover-color;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);

      .dark-theme & {
        color: $global-link-hover-color-dark;
      }
    }

    small {
      color: $global-font-secondary-color;

      .dark-theme & {
        color: $global-link-hover-color-dark;
      }
    }
  }
}
